import axios from 'axios'
import React from 'react'

const Login = () => {
    const [email, setEmail] = React.useState("")
    const [pass, setPass] = React.useState("")

    const handleSubmit = () => {
        const payload = {
            pass: pass,
            email: email
        }
        fetch(`https://angry-tweed-jacket-tuna.cyclic.app/user/login`, {
            method:"POST",
            body:JSON.stringify(payload),
        
            headers:{
                'Content-Type':'application/json'
            }
          
        })  
        .then((res) => res.json())
        .then((res) =>localStorage.setItem("token",res.token))
        .then((res)=>console.log(res.json()))
        .catch((err) => console.log(err))
    }

    return (
        <>
            <div>Login</div>
            <input type="text" placeholder='enter email' value={email} onChange={(e) => setEmail(e.target.value)} />
            <input type="text" placeholder='enterPassword' value={pass} onChange={(e) => setPass(e.target.value)} />
            <input type="submit" onClick={handleSubmit} />
        </>
    )
}


export default Login