import React from 'react'
import { Link } from 'react-router-dom'
const Navbar = () => {
  return (
    <div style={{display:"flex",border:"1px solid red",justifyContent:"space-around"}}>
      <Link style={{textDecoration:"none",backgroundColor:"cyan",color:"black"}} to ="/createNotes">createNotes</Link>
      <Link  style={{textDecoration:"none"}} to ="/register">register</Link>
      
      <Link   style={{textDecoration:"none"}} to ="/notes">notes</Link>
      <Link   style={{textDecoration:"none"}} to ="/login">login</Link>
    </div>
  )
}

export default Navbar
