import React from 'react'
import { useNavigate, useParams } from "react-router-dom"
const Edit = () => {
    const { id } = useParams()
    const [title, setTitle] = React.useState("")
    const navigate =useNavigate()
    // console.log(id)

    const handleSubmit = () => {
        const payload = {
                title:title,
            }
        // console.log(2)

            fetch(`https://angry-tweed-jacket-tuna.cyclic.app/notes/update/${id}`,{
                method: "PATCH",
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type':'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            })
            .then(res => res.json())
            .then(res => console.log(res))
            .catch(err => console.log(err))

        }
    React.useEffect(()=>{
        handleSubmit()
    },[])

    return (
        <>
            <div>Edit</div>
            <input type="text" placeholder='title' value={title} onChange={(e) => setTitle(e.target.value)} />
            <input type="submit" onClick={handleSubmit} />
        </>
    )
}

export default Edit