import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Notes = () => {
    const [notes, setNotes] = React.useState("")
    const navigate = useNavigate()

    React.useEffect(() => {
        fetch("https://angry-tweed-jacket-tuna.cyclic.app/notes", {
            headers: {
                "Authorization": localStorage.getItem("token")
            }
        }).then((res) => res.json()).then((res) =>
            setNotes(res))
            .catch((err) => console.log(err))
    }, [])

    // console.log(notes)
    const handleDelete = (notesId) => {
        fetch(`https://angry-tweed-jacket-tuna.cyclic.app/notes/delete/${notesId}`, {
            method: "DELETE",
            headers: {
                "Authorization": localStorage.getItem("token")
            }
        })
        window.location.reload()
    }






    return (
        <>
            <div>notes</div>
            <h1>All the notes are there</h1>
            {
                notes.length > 0 && notes.map((ele) => (
                    <div style={{ border: "1px solid red", height: "240px", width: "300px", margin: "auto" }}>
                        {/* {console.log(ele.title)} */}
                        <h1>{ele.title}</h1>
                        <p>{ele.notes}</p>
                        <p>{ele.category}</p>
                        <h5>{ele.author}</h5>
                        <button onClick={() => handleDelete(ele._id)}>Delete</button>
                        <Link to={(`/update/${ele._id}`)}><button>Edit</button></Link>
                    </div>
                ))
            }
        </>
    )
}

export default Notes