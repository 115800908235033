import './App.css';
import {Routes,Route} from "react-router-dom";
import Register from './components/Register';
import Login from './components/Login';
import CreateNotes from './components/CreateNotes';
import Notes from "./components/Notes"
import Edit from './components/Edit';
import Navbar from './Navbar/Navbar';
function App() {
  return (
    
    <div className="App">
       <Navbar/>
    <Routes>
      <Route path="/register"element={<Register/>}></Route>
      <Route path="/login"element={<Login/>}></Route>
      <Route path="/createNotes"element={<CreateNotes/>}></Route>
      <Route path="/notes"element={<Notes/>}></Route>
      <Route path="/update/:id"element={<Edit/>}/>
    </Routes>
    </div>
  );
}

export default App;
