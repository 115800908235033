import React from 'react'
import axios from "axios"
const Register = () => {
    const [name, setName] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [pass, setPass] = React.useState("")
    const [age, setAge] = React.useState("")

    const handleSubmit = () => {
        const payload = {
             name,
             pass,
             email,
             age
        }
        console.log(payload)
        fetch("https://angry-tweed-jacket-tuna.cyclic.app/user/register", {
            method: "POST",
            body:JSON.stringify(payload),
            headers: {
                "Content-type":"application/json"
            }
        })
        .then(res => res.json())
      .then(res => console.log(res))
     .catch(err => console.log(err))

    // axios.post("http://localhost:4500/users/register",payload)
    // .then((res)=>{
    //    console.log(res.config)
    // }).catch((err)=>{
    //     console.log(err)
    // })
   


    }

    return (
        <>
            <div>Register</div>
            <input type="text" placeholder='enter name' value={name} onChange={(e) => setName(e.target.value)} />
            <input type="text" placeholder='enter email' value={email} onChange={(e) => setEmail(e.target.value)} />
            <input type="text" placeholder='enterPassword' value={pass} onChange={(e) => setPass(e.target.value)} />
            <input type="text" placeholder='enter age' value={age} onChange={(e) => setAge(e.target.value)} />
            <input type="submit" onClick={handleSubmit} />
        </>
    )
}

export default Register