import React from 'react'

const CreateNotes = () => {
    const [title, setTitle] = React.useState("")
    const [note, setNote] = React.useState("")
    const [category, setCategory] = React.useState("")

    const handleSubmit = () => {
        const payload = {
            title: title,
            note: note,
           category:category
        }
        fetch("https://angry-tweed-jacket-tuna.cyclic.app/notes/create", {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                "Content-Type": "application/json",
                "Authorization":localStorage.getItem("token")
            }
        }).then((res) => res.json())
            .then((res) => console.log(res))
            .catch((err) => console.log(err))
    }

    return (
        <>
            <div>notes</div>
            <input type="text" placeholder='enter title' value={title} onChange={(e) => setTitle(e.target.value)} />
            <input type="text" placeholder='enter note' value={note} onChange={(e) => setNote(e.target.value)} />
            <input type="text" placeholder='entercategory' value={category} onChange={(e) => setCategory(e.target.value)} />
            <input type="submit" onClick={handleSubmit} />
        </>
    )
}

export default CreateNotes